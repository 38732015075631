import * as React from 'react';
import { graphql } from 'gatsby';

import SEO from '../components/seo';
import Empty from '../components/empty';
import Link from '../components/link';
import Box from '../components/box';
import Footer from '../components/footer';

const NotFoundPage = ({ data }) => {
  const {
    site: {
      siteMetadata: { paths },
    },
  } = data;

  return (
    <>
      <SEO title="Ramo Mujagic - Error 404" />

      <Box className="vertical h-center v-center" style={{ flex: 1 }}>
        <main>
          <Empty title="404! Things are missing here" description="" />
          <Box className="horizontal h-center">
            <p>
              I want to read more, take me to the{' '}
              <Link to={`${paths.blog}`}>blog!</Link>
            </p>
          </Box>
        </main>
      </Box>

      <Footer />
    </>
  );
};

export default NotFoundPage;

export const query = graphql`
  query {
    site {
      siteMetadata {
        paths {
          blog
        }
      }
    }
  }
`;
